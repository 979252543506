import React, { useCallback, useState } from 'react'

import { uuid } from '@/utils/uuid'

import { SnackBarShowRequest, SnackBarSettings } from './types'
import { SnackBars } from './snackBars'
import { SnackBarsContext } from './context'

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/feedback-snackbar-since-v2-6-0--all)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555-3472&t=XvcIoF0tSbk5FbWR-0)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { SnackBarProvider } from '@interco/inter-ui/components/SnackBar'
 *
 * export const App = () => {
 *   return (
 *     <SnackBarProvider>
 *
 *       <MyPage />
 *       {...}
 *
 *     </SnackBarProvider>
 *   )
 * }
 * ```
 */
export const SnackBarProvider = ({ children }: PropsWithRequiredChildren) => {
  const [snackBars, setSnackBars] = useState<SnackBarSettings[]>([])

  const handleCloseSnackBar = useCallback((id: string) => {
    setSnackBars((prevState) => prevState.filter((snack) => snack.id !== id))
  }, [])

  const handleCloseAllSnackBars = useCallback(() => {
    setSnackBars([])
  }, [])

  const handleShowSnackBar = useCallback(
    (settings: SnackBarShowRequest | SnackBarShowRequest[]) => {
      if (Array.isArray(settings)) {
        const newSnackBars = settings.map((config) => ({
          ...config,
          time: config.time || 5_000,
          id: uuid(),
        }))

        setSnackBars((prevState) => [...prevState, ...newSnackBars])
        return
      }

      setSnackBars((prevState) => [
        ...prevState,
        {
          ...settings,
          id: uuid(),
          time: settings.time || 5_000,
        },
      ])
    },
    [],
  )

  return (
    <SnackBarsContext.Provider
      value={{
        closeSnackBar: handleCloseSnackBar,
        closeAllSnackBars: handleCloseAllSnackBars,
        showSnackBar: handleShowSnackBar,
        snackBars,
      }}
    >
      {children}
      <SnackBars />
    </SnackBarsContext.Provider>
  )
}
