import React, { useCallback, useState, useRef, useEffect, useMemo, ReactNode } from 'react'

import AddCircle from '@interco/icons/bidis/v2/action/add-circle'

import { Text } from '../Text'
import * as S from './styles'
import { ChipsProps, ChipsMode } from './types'

export type { ChipsProps, ChipsMode } from './types'

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/forms-chips-since-v2-9-0--dropdown)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555-3459&t=qb8lAK7gTKv9MbYv-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { Chips, ChipsItem } from '@interco/inter-ui/components/Chips'
 *
 * const options: ChipsItem[] = [
 *   { label: 'item 1', value: 'item 1' },
 *   { label: 'item 2', value: 'item 2' },
 *   { label: 'item 3', value: 'item 3' },
 *   { label: 'item 4', value: 'item 4' },
 *   { label: 'item 5', value: 'item 5' },
 *   { label: 'item 6', value: 'item 6' },
 * ]
 *
 * export const MyPage = () => {
 *   const [value, setValue] = useState<ChipsItem>()
 *
 *   return (
 *     <Chips
 *       mode="dropdown"
 *       options={options}
 *       onClickItem={setValue}
 *       selectedItem={value}
 *     />
 *   )
 * }
 * ```
 */
export const Chips = React.memo(
  ({
    label,
    variant = 'caption-2',
    mode,
    counter,
    isActive = false,
    options,
    selectedItem,
    disabled = false,
    onClickItem,
    onClick,
    rightIcon = null,
    fullWidth = true,
    ...attrs
  }: ChipsProps) => {
    const [isExpanded, setExpanded] = useState(false)
    const dropdownRef = useRef<HTMLUListElement>(null)
    const isNormal = useMemo(() => mode === 'normal', [mode])
    const isActiveAndNotDisabled = useMemo(() => !disabled && isActive, [disabled, isActive])
    const isOnlyPlus = useMemo(() => !label && mode === 'plus', [label, mode])

    useEffect(() => {
      if (mode === 'dropdown' && dropdownRef.current) {
        dropdownRef.current.style.maxHeight = isExpanded ? '100vh' : 'var(--chips-height)'
        dropdownRef.current.style.padding = isExpanded ? S.DROPDOWN_PADDING_CONFIG : '0px'
      }
    }, [isExpanded, mode])

    const onClickContainer = useCallback(() => {
      if (isNormal && onClick) {
        onClick()
      } else {
        setExpanded((prevState) => !prevState)
      }
    }, [isNormal, onClick])

    const RenderDropdownMenu = useMemo(
      () => (
        <S.Dropdown ref={dropdownRef} isActive={isActiveAndNotDisabled}>
          {options &&
            options.map((option) => {
              const isSelectedItem = !!selectedItem && option.value === selectedItem.value
              return (
                <S.DropdownItem
                  key={option.label}
                  data-testid="item"
                  isActive={isActiveAndNotDisabled}
                  onClick={() => {
                    onClickItem?.(option)
                  }}
                >
                  <S.Text isSelectedItem={isSelectedItem}>{option.label}</S.Text>
                </S.DropdownItem>
              )
            })}
        </S.Dropdown>
      ),
      [isActiveAndNotDisabled, onClickItem, options, selectedItem],
    )

    const rightIconMapper: Record<ChipsMode, ReactNode> = useMemo(
      () => ({
        normal: (
          <S.RightIconContainer>
            {rightIcon && React.isValidElement(rightIcon) && isActive && !disabled
              ? React.cloneElement(
                  rightIcon,
                  { color: 'var(--neutral-theme)' } as Partial<unknown>,
                  null,
                )
              : rightIcon}
          </S.RightIconContainer>
        ),
        dropdown: (
          <S.DropdownIconContainer data-testid="arrow-down-icon">
            <S.ChevronMore isActive={isActiveAndNotDisabled} />
          </S.DropdownIconContainer>
        ),
        counter: (
          <S.CountCircle isActive={isActiveAndNotDisabled} data-testid="circle-icon">
            <S.Text bold>{counter}</S.Text>
          </S.CountCircle>
        ),
        plus: (
          <S.PlusCircle
            isActive={isActiveAndNotDisabled}
            isOnlyPlus={isOnlyPlus}
            data-testid="plus-icon"
            disabled={disabled}
          >
            <AddCircle
              strokeWidth={10}
              width={14}
              height={14}
              color={
                isActiveAndNotDisabled
                  ? 'var(--chips-active-text-color)'
                  : 'var(--chips-text-color)'
              }
            />
          </S.PlusCircle>
        ),
      }),
      [counter, disabled, isActive, isActiveAndNotDisabled, isOnlyPlus, rightIcon],
    )

    return (
      <S.Container
        {...attrs}
        onClick={!disabled ? onClickContainer : undefined}
        disabled={disabled}
        fullWidth={fullWidth}
      >
        {mode === 'dropdown' && RenderDropdownMenu}

        <S.LabelContainer
          isNormal={isNormal}
          isActive={isActiveAndNotDisabled}
          isOnlyPlus={isOnlyPlus}
        >
          {!isOnlyPlus && (
            <Text variant={variant} colorWeight={500}>
              {label}
            </Text>
          )}

          {rightIconMapper[mode]}
        </S.LabelContainer>
      </S.Container>
    )
  },
)
