import { useContext } from 'react'

import { SnackBarsContext } from './context'

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/feedback-snackbar-since-v2-6-0--all)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555-3472&t=XvcIoF0tSbk5FbWR-0)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { useSnackBar, SnackBarProvider } from '@interco/inter-ui/components/SnackBar'
 * import { Button } from '@interco/inter-ui/components/Button'
 *
 * @attention
 * // Para utilizar as notificações você precisa adicionar, 'SnackBarProvider' no seu projeto.
 *
 * export const App = () => {
 *   return (
 *     <SnackBarProvider>
 *
 *       <MyPage />
 *       {...}
 *
 *     </SnackBarProvider>
 *   )
 * }
 *
 * export const MyPage = () => {
 *   const { showSnackBar } = useSnackBar()
 *
 *   return (
 *     <>
 *       <Button
 *         onClick={() =>
 *           showSnackBar({
 *             title: 'Title snackBar success',
 *             content: 'Content snackBar',
 *             type: 'SUCCESS',
 *           })
 *         }
 *       >
 *         Open SnackBar
 *       </Button>
 *     </>
 *   )
 * }
 * ```
 */
export const useSnackBar = () => useContext(SnackBarsContext)
